export class CookieStorage {
  static set = (key, value, options = {}) => {
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString()
    }
    let updatedCookie =
      encodeURIComponent(key) + '=' + encodeURIComponent(value)

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey
      let optionValue = options[optionKey]
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue
      }
    }

    document.cookie = updatedCookie
  }

  static getAll = () => {
    const cookies = {}
    document.cookie.split(';').forEach(cookie => {
      const [key, value] = cookie.split('=')
      cookie[key] = value
    })
    return cookies
  }

  static get = key => {
    let matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  static delete = key => {
    CookieStorage.set(key, '', {
      'max-age': -1,
    })
  }

  static clearAll = () => {
    const cookies = document.cookie.split(';')
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
    })
  }
}

import { CookieStorage } from './CookieService'
const apiUrl = process.env.MYSTORE_MS_API_URL
const overRideOrigin = process.env.X_OVERRIDE_ORIGIN

export function HttpService(callback) {
  return {
    httpRequest: (resource, options, url = apiUrl) => {
      let overRideOriginTemp = overRideOrigin
      if (overRideOriginTemp.length > 0 && !resource.endsWith('?')) {
        overRideOriginTemp = '&' + overRideOriginTemp
      }
      resource = `${resource}${overRideOriginTemp}`
      if (options.hasOwnProperty('headers') === false) {
        options['headers'] = {}
      }
      if (options['headers'].hasOwnProperty('Content-Type') === false) {
        options['headers']['Accept'] = 'application/json'
        options['headers']['Content-Type'] = 'application/json'
        options['headers']['Authorization'] = CookieStorage.get('token')
        // options['headers']['s'] = AuthService().getAuthToken()
      }
      if (window.navigator.onLine) {
        let status = ''
        return fetch(url + resource, options)
          .then(function(response) {
            if (!response.ok) {
              return Promise.reject(response)
            }
            status = response.status
            if (callback) {
              callback(response)
            }
            return response.json()
          })
          .then(function(myJson) {
            myJson['status'] = status
            return myJson
          })
      } else {
        throw String('No internet connection !')
      }
    },
  }
}

export default HttpService

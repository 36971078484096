import React, { Component } from 'react'
import styled from 'styled-components'
import { Image } from 'rebass'
import { navigate } from 'gatsby-link'
import TasksIcon from '../assets/svg-icons/checklist.svg'
import UsersIcon from '../assets/svg-icons/user-group.svg'
import PostIcon from '../assets/svg-icons/conversation.svg'
import NotificationIcon from '../assets/svg-icons/bell.svg'
import ReportIcon from '../assets/svg-icons/graph-line.svg'
import GearIcon from '../assets/svg-icons/gear.svg'
import DeviceDesktop from '../assets/svg-icons/device-desktop.svg'
import POPIcon from '../assets/svg-icons/vectorpaint.svg'
import GroupIcon from '../assets/svg-icons/network-3.svg'
import TagIcon from '../assets/svg-icons/tag-svgrepo-com.svg'
import ETLIcon from '../assets/svg-icons/data-management.svg'
import { connect } from 'redux-zero/react'
import posed from 'react-pose'
import './SidebarStyle.css'
const Aside = styled.aside``

const sidebarProps = {
  visible: {
    x: 0,
    transition: { type: 'tween' },
  },
  hidden: {
    x: -220,
    transition: { type: 'tween' },
  },
}
const AniWrapper = styled(posed.div(sidebarProps))`
  width: 220px;
  background: white;
  padding: 0 1rem;

  @media (max-width: 992px) {
    position: fixed;
    height: 100%;
    z-index: 2;
  }
`

const NavList = styled.div`
  padding: 0.625rem 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;
  flex-direction: column;
`
const NavItem = styled.a`
  color: #718eb1;
  margin: 0;
  padding: 1.5rem 1.25rem 1.5rem 1.25rem;
  font-size: 0.8125rem;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid rgba(47, 46, 68, 0.03);
  cursor: pointer;
  &:hover {
    color: #2f2e44;
    background: #fbfdff;
  }
`

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: [
        {
          link: '/users/',
          title: 'Users',
          icon: UsersIcon,
        },

        {
          link: '/groups/',
          title: 'Groups',
          icon: GroupIcon,
        },
        {
          link: '/create-pop/',
          title: 'Create POP',
          icon: POPIcon,
        },

        {
          link: '/tags/',
          title: 'Tags',
          icon: TagIcon,
        },
        {
          link: '/tasks/',
          title: 'Tasks',
          icon: TasksIcon,
        },
        {
          link: '/posts/',
          title: 'Posts',
          icon: PostIcon,
        },
        {
          link: '/notification/',
          title: 'Push Notifications',
          icon: NotificationIcon,
        },
        {
          link: '/reports/',
          title: 'Report',
          icon: ReportIcon,
        },
        {
          link: '/retailConfig/',
          title: 'Retail Settings',
          icon: GearIcon,
        },
        {
          link: '/external-etls/',
          title: 'ETLS',
          icon: ETLIcon,
        },
        {
          link:
            'https://datastudio.google.com/embed/u/0/reporting/5286ff59-b172-4189-b0c3-ba71dfd35b01/page/ETE7C',
          title: 'Analytics',
          icon: DeviceDesktop,
        },
      ],
    }
  }

  render() {
    return (
      <AniWrapper
        width="220px"
        px={2}
        bg="white"
        pose={this.props.drawer ? 'visible' : 'hidden'}
      >
        <Aside>
          <NavList>
            {this.state.pages.map((item, index) => (
              <NavItem key={index} onClick={() => navigate(item.link)}>
                <Image mr={3} src={item.icon} width={20} />
                <span>{item.title}</span>
              </NavItem>
            ))}
          </NavList>
        </Aside>
      </AniWrapper>
    )
  }
}

const mapStateToProps = ({ drawer }) => ({
  drawer,
})

export default connect(mapStateToProps)(Sidebar)

import moment from 'moment'
import { isSolid } from './taskHandler'
import Keys from './Keys.json'

export const epochTime2date = (epoch, _format = 'YYYY-MM-DDTHH:mm:ss') => {
  return moment(epoch * 1000).format(_format)
}

export const getCurrentWeekDates = () => {
  let startOfWeek = moment()
    .day(0)
    .format('YYYY-MM-DD')
  let endOfWeek = moment()
    .day(6)
    .format('YYYY-MM-DD')
  return [startOfWeek, endOfWeek]
}

export const getBeginningOfTheWeek = (format = 'YYYY-MM-DD') => {
  return moment()
    .day(0)
    .format(format)
}

export const getLastWeekDates = () => {
  let startOfWeek = moment()
    .day(-7)
    .format('YYYY-MM-DD')
  let endOfWeek = moment()
    .day(-1)
    .format('YYYY-MM-DD')
  return [startOfWeek, endOfWeek]
}

export const getNow = (format = 'YYYY-MM-DDTHH:mm:ss') => {
  return moment().format(format)
}

export const getNextWeek = (format = 'YYYY-MM-DDTHH:mm:ss') => {
  return moment()
    .add(7, 'd')
    .format(format)
}

export const timestamp2date = cell => {
  if (cell !== undefined) {
    return moment(cell, 'YYYYMMDDHHmmss').format('YYYY-MM-DDTHH:mm:ss')
  }
  return moment().format('YYYY-MM-DDTHH:mm:ss')
}
export const getDiffInHours = (val1, val2) => {
  if (val1 !== undefined && val2 !== undefined) {
    return moment(val2, 'YYYY-MM-DDTHH:mm').diff(
      moment(val1, 'YYYY-MM-DDTHH:mm'),
      'hours'
    )
  }
}
export const timestamp2dateWithoutSec = cell => {
  if (cell !== undefined) {
    return moment(cell, 'YYYYMMDDHHmm').format('YYYY-MM-DDTHH:mm')
  }
  return moment().format('YYYY-MM-DDTHH:mm')
}

export const timestamp2tableDate = (cell, format = 'YYYYMMDDHHmmss') => {
  if (cell !== undefined) {
    return moment(cell, format).format('YYYY-MM-DD HH:mm:ss')
  }
  return moment().format('YYYY-MM-DD HH:mm:ss')
}

export const date2timestamp = date => {
  return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('YYYYMMDDHHmmss')
}

export const getReportLimit = (
  reports_fetch_range,
  report_fetch_by_range_value
) => {
  if (reports_fetch_range === 'by_range') {
    return report_fetch_by_range_value
  } else if (reports_fetch_range === 'month_to_date') {
    return Number(getNow('DD'))
  } else if (reports_fetch_range === 'week_to_date') {
    return (
      Number(getNow('YYYYMMDD')) - Number(getBeginningOfTheWeek('YYYYMMDD'))
    )
  } else {
    console.log("didn't set a limit")
    return 7
  }
}
export const dateNow = () => {
  return moment().format('DD/MM/YYYY HH:mm')
}

export default {
  getCurrentWeekDates,
  getLastWeekDates,
  timestamp2date,
  timestamp2tableDate,
  date2timestamp,
  getNow,
  getNextWeek,
  epochTime2date,
}

import React from 'react'
import PropTypes from 'prop-types'
import { siteMetadata } from '../../gatsby-config'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'
import styled, { createGlobalStyle } from 'styled-components'
import { Provider, Heading, Container, Flex, Box, Drawer } from 'rebass'
import SiteNavi from './SiteNavi/index'
import Header from './header'
import Sidebar from './Sidebar'
import Login from './Login'
import FullScreenLoader from './FullScreenLoader'
// import './layout.css'
import 'normalize.css'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import AlertContainer from '../components/Elements/Alert/AlertContainer'
import Alert from './Elements/Alert/Alert'
import ErrorPage from './Elements/ErrorPage'

createGlobalStyle`
  * { box-sizing: border-box; }
  html {
    height: 100%;
  -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
  }
  body, html {
    font-size: 100%;
    line-height: 1.5;
    letter-spacing: .02rem;
  }
  body {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    color: #617182;
    background-color: rgba(241, 247, 255, 0.23);
  }
  ::selection {
    background: #FFF9CC;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
  }
    
  
`

const Wrapper = styled(Flex)`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
`

const LoginWrapper = styled(Flex)`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  max-width: 400px;
  margin: auto;
`

const PageLayout = styled(Box)`
  max-width: ${props => (props.maxWidth ? '1600px' : '1800px')};
  margin: 0 auto;
`

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorInfo: '',
      error: null,
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error.toString(),
      errorInfo: info.componentStack,
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage
          errorMassage={this.state.error}
          errorSuck={this.state.errorInfo}
        />
      )
    }
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
            <Provider
              theme={{
                fonts: {
                  sans:
                    '"Source Sans Pro","Avenir Next", Helvetica, sans-serif',
                },
                breakpoints: ['32em', '48em', '74em', '90em'],
                fontSizes: [14, 16, 24, 32, 42, 52],
                colors: {
                  black: '#2f2e44',
                },
              }}
            >
              <SiteNavi title={siteMetadata.title} />
              <Wrapper className="wrapper">
                <Sidebar />

                <PageLayout
                  maxWidth={this.props.drawer}
                  width={this.props.width || 1}
                  px={this.props.px || 2}
                  py={this.props.py || 4}
                >
                  {this.props.children}
                  <AlertContainer>
                    {this.props.alerts.map((item, index) => (
                      <Alert key={index} type={item.type} id={item.id}>
                        {item.text}
                      </Alert>
                    ))}
                  </AlertContainer>
                </PageLayout>
              </Wrapper>
            </Provider>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = ({ drawer, alerts }) => ({
  drawer,
  alerts,
})
export default connect(mapStateToProps, actions)(Layout)

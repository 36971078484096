export const userAgent = () => {
  return 'ipad'
}

export const osType = () => {
  return 'mac'
}

export const storage = {
  setKey: (key, data) => {
    typeof window !== 'undefined' && window.localStorage.setItem(key, data)
    return data
  },
  getJSONData: key => {
    return JSON.parse(
      (typeof window !== 'undefined' && window.localStorage.getItem(key)) ||
        '{}'
    )
  },
  getJSONString: key => {
    return JSON.stringify(
      (typeof window !== 'undefined' && window.localStorage.getItem(key)) ||
        '{}'
    )
  },
  deleteKey: key => {
    typeof window !== 'undefined' && window.localStorage.removeItem(key)
  },
}

export function getRetailIdOrSubRetailId(groups, groupId) {
  if (Object.keys(groups).length === 0 || !groupId || !groups[groupId]) {
    return null
  }
  let currentGroup = groups[groupId]

  while (currentGroup) {
    const { config, parent, rid } = currentGroup
    if (parent === null) {
      return rid
    }
    if (config && config.sub_retail_id) {
      return config.sub_retail_id
    }
    currentGroup = groups[parent]
  }

  return null
}

export const getCurrentDateAndTime = () => {
  let currentDate = new Date()
  let date = currentDate.getDate()
  let month = currentDate.getMonth() + 1
  let year = currentDate.getFullYear()
  let hours = currentDate.getHours()
  let minutes = currentDate.getMinutes()
  let seconds = currentDate.getSeconds()

  let dateString = `${year.toString()}${month.toString()}${date.toString()}${hours.toString()}${minutes.toString()}${seconds.toString()}`
  return dateString
}

export const getCurrentYearDayNumber = () => {
  let now = new Date()
  let start = new Date(now.getFullYear(), 0, 0)
  let diff = now - start
  let oneDay = 1000 * 60 * 60 * 24
  let dayNumber = Math.floor(diff / oneDay)
  return dayNumber
}

export const extractHashParams = hashString => {
  const hashParams = {}
  hashString.split('&').forEach(el => {
    const [key, value] = el.split('=')
    const keyWithoutHash = key.replace('#', '')
    hashParams[keyWithoutHash] = value
  })
  return hashParams
}

export const getLocalStorageKey = (retailId, group, prefix = null) =>
  `${prefix ? prefix + '-' : ''}${retailId}-${group}`

export const validateByRegExp = (regexp, value) => {
  const regExp = new RegExp(regexp)
  return regExp.test(value)
}

export default {
  storage,
  osType,
  userAgent,
  getCurrentDateAndTime,
}

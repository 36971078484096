import uuidv1 from 'uuid/v1'
import keys from './Keys'
import { getNow } from './dateHandler'
import { isNil, get, isEmpty } from 'lodash'
import isPlainObject from 'lodash/isPlainObject'
import actions from '../store/actions'
import React from 'react'
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import Keys from './Keys.json'
import { LocalStorage } from '../services/localStorage'
import { getLocalStorageKey } from '.'

export const handleResponseError = status => {
  if (status === '401') {
    actions.logout()
    // storage.deleteKey('token')
    alert(
      'Got 401 response as status, means your token is not valid no more try to login again'
    )
    // navigate('/')
  }
}

export const isSolid = val => {
  if (isNil(val)) {
    // value is null or undefined
    return false
  } else {
    // is not null or undefined

    if (val.hasOwnProperty('length')) {
      if (val.length > 0) {
        // is STRING or ARRAY
        return true
      } else {
        // is empty STRING or ARRAY
        return false
      }
    } else if (isPlainObject(val)) {
      if (Object.keys(val).length > 0) {
        // is OBJECT
        return true
      } else {
        // is empty OBJECT
        return false
      }
    } else {
      // is NUMBER
      return true
    }
  }
}

function sortProperties(obj) {
  // convert object into array
  var sortable = []
  for (var key in obj)
    if (obj.hasOwnProperty(key)) sortable.push([key, obj[key]]) // each item is an array in format [key, value]

  // sort items by value
  sortable.sort(function(a, b) {
    if (a[0] > b[0]) {
      return 1
    }
    if (a[0] < b[0]) {
      return -1
    } // compare numbers
  })
  return sortable // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export const formatAreas = areas => {
  let newAreas = {}
  if (isSolid(areas)) {
    Object.keys(areas).forEach(sid => {
      if (!isNil(newAreas[areas[sid]])) {
        newAreas[areas[sid]].push(sid)
      } else {
        newAreas[areas[sid]] = [sid]
      }
    })
  }
  return newAreas
}

export const formatStores = (stores, areas) => {
  let newStores = []
  if (isSolid(stores)) {
    Object.keys(stores).forEach(sid => {
      newStores.push({ sid: sid, store_name: stores[sid], area: areas[sid] })
    })
  }
  return newStores
}

export const printStoresOptions = (userRidsDetails, selected_rid) => {
  try {
    const store_names_sorted = sortProperties(userRidsDetails[selected_rid])
    return store_names_sorted.map(item => {
      return (
        <option value={item[0]} key={item[0]}>
          {item[0]}-{item[1]}
        </option>
      )
    })
  } catch (e) {
    return <option> no sids for this retail </option>
  }
}

export const convertUnderscoreNameToLabel = underscoreName => {
  if (!isNil(underscoreName) && typeof underscoreName === 'string') {
    let label = underscoreName.toLowerCase().replace(/_/g, ' ')
    return label.charAt(0).toUpperCase() + label.slice(1)
  }
  return ''
}

export const printMenuOptions = menuOptions => {
  let preparedString = ''
  const separate = ', \n'
  if (!isNil(menuOptions)) {
    menuOptions.forEach(opt => {
      preparedString =
        preparedString + convertUnderscoreNameToLabel(opt.title) + separate
    })
  }
  if (preparedString.endsWith(separate)) {
    preparedString = preparedString.slice(0, -separate.length)
  }
  return preparedString
}

export const findTaskIndex = (taskArr, task) => {
  let index = -1
  taskArr.forEach((cur_task, i) => {
    if (cur_task.tid === task.tid) {
      index = i
    }
  })
  return index
}
export const isTaskActive = task => {
  if (task.active === false) {
    return false
  } else {
    const now = getNow('YYYYMMDDTHHmmss')
    if (task.from_ts > now || now > task.to_ts) {
      return false
    }
  }
  return true
}

export const removeKeyFromObject = (obj, _key) => {
  delete obj[_key]
  return obj
}

export const sortTasks = (tasks, taskOrderChanged = null, reindex = true) => {
  tasks.sort(function(a, b) {
    var diff = a.view - b.view
    if (diff !== 0) {
      return diff
    }
    return a.order - b.order
  })
  return tasks
}

export const getAplicationTasks = tasks => {
  var tasks = []
  tasks.forEach(task => {
    if (task.type !== 1) {
      tasks.push(task)
    }
  })
  return tasks
}

export const getReportTasks = tasks => {
  var reportTasks = []
  tasks.forEach(task => {
    if (task.type === 1) {
      reportTasks.push(task)
    }
  })
  return reportTasks
}

export const emptyStrToNull = str => {
  if (!isNil(str)) {
    if (str.trim() === '') {
      return null
    }
    return str.trim()
  }
}

export const isEmptyStr = str => {
  if (!isNil(str) && typeof str === 'string' && str.trim() === '') {
    return true
  }
  return false
}

export const getActiveTasks = tasks => {
  var activeTasks = []
  tasks.forEach(task => {
    if (task.active === true) {
      const now = getNow('YYYYMMDDTHHmmss')
      if (task.from_ts < now && now < task.to_ts) {
        activeTasks.push(task)
      }
    }
  })
  return activeTasks
}

export const generateTagList = infoLst => {
  let info_tags = []
  infoLst.forEach(tag => {
    info_tags.push({ tag: tag })
  })
  return info_tags
}

export const tagsToList = tags => {
  return tags.map(i => {
    return i.tag
  })
}

export const generateID = () => {
  return uuidv1()
}

export const getPairId = (id, id_2) => {
  return `${id}${keys.TYPE_2_SEPARATOR}${id_2}`
}

export const splitPairId = pairedId => {
  return pairedId.split(keys.TYPE_2_SEPARATOR)
}

export const getNewEmptyTaskData = sid => {
  let taskOId = generateID()
  return {
    oid: taskOId,
    categories_order: [],
    categories: {},
    data: {},
    sid: sid,
  }
}

export const reorderTasks = (tasks, addChangedTask) => {
  tasks.forEach((task, index) => {
    if (index !== task.order) {
      addChangedTask(task.task_id)
    }
    task.order = index
  })
  return tasks
}

export const getRsid = (rid, sid) => {
  return `${rid}-${sid}`
}

export const setNewOrder = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    new_index = arr.length - 1
  }
  if (old_index >= arr.length) {
    old_index = arr.length - 1
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return [...Array.from(new Set(arr))] // for testing
}

export const addNewItemToOrder = (arr, index, itemId) => {
  arr.splice(index, 0, itemId)
  return [...Array.from(new Set(arr))]
}

export const validate = (form, toValidate) => {
  var BreakException = {}
  try {
    toValidate.forEach(filed => {
      let value = form[filed]
      if (value === undefined) {
        BreakException['text'] = `this form is measing ${filed} filed`
        throw BreakException
      }
      if (value.trim() === '') {
        BreakException['text'] = `${filed} should not be empty `
        throw BreakException
      }
    })
  } catch (e) {
    if (e === BreakException) {
      throw BreakException
    }
  }
  return true
}

export const mergeTaskData = (targetData, srcData, srcTask, sid) => {
  if (targetData === undefined) {
    targetData = getNewEmptyTaskData(sid)
  }
  let task_type = srcTask.type
  Object.keys(srcData.categories).forEach(category => {
    if (Object.keys(targetData.categories).indexOf(category) < 0) {
      targetData.categories[category] = srcData.categories[category]
      targetData.categories_order.push(category)
    }
  })
  targetData.categories_order = [
    ...Array.from(new Set(targetData.categories_order)),
  ]

  let temp_data = { ...targetData.data }
  if (task_type === keys.TYPE_7) {
    srcTask.categories_order.forEach(cat => {
      srcTask.categories[cat].data_order.forEach(item => {
        if (Object.keys(targetData.data).indexOf(item) < 0) {
          let item_data = srcData.data[item]
          if (item_data !== undefined) {
            temp_data[item] = item_data
          }
        }
      })
    })
  } else if (task_type === keys.TYPE_2) {
    srcTask.data_order.forEach(pairedId => {
      let items = splitPairId(pairedId)
      items.forEach(item => {
        if (Object.keys(targetData.data).indexOf(item) < 0) {
          let item_data = srcData.data[item]
          if (item_data !== undefined) {
            temp_data[item] = item_data
          }
        }
      })
    })
  } else {
    srcTask.data_order.forEach(item => {
      if (Object.keys(targetData.data).indexOf(item) < 0) {
        let item_data = srcData.data[item]
        if (item_data !== undefined) {
          temp_data[item] = item_data
        }
      }
    })
  }
  targetData.data = temp_data
  return { ...targetData }
}

export const getEditorInitialValue = txt => {
  const contentBlock = convertFromHTML(txt)
  if (contentBlock && contentBlock.contentBlocks !== null) {
    const contentState = ContentState.createFromBlockArray(contentBlock)
    return EditorState.createWithContent(contentState)
  }
}

export const inReachSubTaskWithOptional = (subTask, optionalData) => {
  Object.keys(optionalData).forEach(item => {
    if (item === keys.COVER_IMAGE) {
      if (optionalData[item] !== '') {
        subTask[item] = optionalData[item]
      }
    } else if (
      (item === keys.DESC &&
        draftToHtml(convertToRaw(optionalData[item].getCurrentContent())) !==
          keys.EMPTY_WYSIG &&
        optionalData[item]._immutable.count() > 0) ||
      (item !== keys.DESC && optionalData[item].length > 0)
    ) {
      subTask[item] = optionalData[item]
      if (item === keys.DESC) {
        subTask[item] = draftToHtml(
          convertToRaw(optionalData[item].getCurrentContent())
        )
      }
    }
  })
  return subTask
}

export const inReachOptional = (obj, optionalData) => {
  Object.keys(optionalData).forEach(item => {
    if (
      !isNil(optionalData[item]) &&
      ((typeof optionalData[item] === 'string' &&
        optionalData[item].trim() !== '') ||
        (typeof optionalData[item] === 'object' &&
          optionalData[item].length > 0) ||
        typeof optionalData[item] === 'number')
    ) {
      obj[item] = optionalData[item]
    }
  })
  return obj
}

export const formatAvatarPath = (cdn, avatar_path, selected_rid, uid) => {
  console.log(cdn)
  if (
    isSolid(cdn) &&
    isSolid(avatar_path) &&
    isSolid(selected_rid) &&
    isSolid(uid)
  ) {
    return cdn + selected_rid + '/' + avatar_path + '/' + uid + '.jpg'
  }
  return null
}

export const getSubTasks = attachedSubTasks => {
  if (isEmpty(attachedSubTasks)) {
    return attachedSubTasks
  }
  let taskSubTasks = {}
  attachedSubTasks.forEach(st => {
    taskSubTasks[st.st_id] = st
  })
  return taskSubTasks
}

export const taskBodyFormatter = (
  task,
  groups,
  userUids,
  rId,
  subTask2Delete,
  attachedSubTasks,
  taskData
) => {
  const taskType = task[Keys.TYPE]
  let body = {
    groups: groups ?? [],
    users: userUids ?? [],
    tasks: task,
    rid: rId,
  }
  if (task[Keys.TYPE] === Keys.TASK_TYPE_GENERAL) {
    if (!isNil(subTask2Delete)) {
      body[Keys.SUB_TASKS_TO_DELETE] = [subTask2Delete]
    }
    if (!isNil(attachedSubTasks)) {
      body[Keys.SUB_TASKS] = Array.isArray(attachedSubTasks)
        ? getSubTasks(attachedSubTasks)
        : attachedSubTasks
    }
  }

  const subtaskPIds = !!attachedSubTasks
    ? Object.values(attachedSubTasks)
        .map(subtask => subtask.pid)
        .flat(Infinity)
    : []

  if (!isNil(taskData)) {
    const formatedTaskData = {}
    subtaskPIds.forEach(id => (formatedTaskData[id] = taskData.data[id]))
    body[Keys.TASKS_DATA] = { ...taskData, data: formatedTaskData }
  }
  return { body, taskType }
}

export const isValidTaskBody = (body, taskType) => {
  let _isValid = true
  if (taskType === Keys.TASK_TYPE_GENERAL && !isNil(body['sub_tasks'])) {
    const subTasks = body['sub_tasks']
    Object.keys(subTasks).forEach(st_id => {
      const subTask = subTasks[st_id]
      if (
        !isNil(get(subTask, Keys.TYPE, null)) &&
        subTask[Keys.TYPE] === Keys.SUB_TASK_PRODUCT
      ) {
        if (
          isNil(get(subTask, Keys.PID, null)) ||
          subTask[Keys.PID].length < 1 ||
          subTask[Keys.PID].some(isNil)
        ) {
          _isValid = false
        }
      }
    })
  }
  return _isValid
}

export const getTaskFromLocalStorage = (rid, groupName, prefix = null) => {
  const localStorageKey = getLocalStorageKey(rid, groupName, prefix)
  return { task: LocalStorage.getData(localStorageKey), LSKey: localStorageKey }
}

export const setTaskToLocalStorage = (rid, groupName, task, prefix = null) => {
  const localStorageKey = getLocalStorageKey(rid, groupName, prefix)
  LocalStorage.setData(localStorageKey, task)
  return localStorageKey
}

export default {
  sortTasks,
  mergeTaskData,
  getPairId,
  splitPairId,
  getRsid,
  setNewOrder,
  validate,
  addNewItemToOrder,
  getNewEmptyTaskData,
  tagsToList,
  generateTagList,
  getActiveTasks,
  getReportTasks,
  getAplicationTasks,
  isTaskActive,
  findTaskIndex,
  emptyStrToNull,
  removeKeyFromObject,
  printStoresOptions,
  convertUnderscoreNameToLabel,
  getEditorInitialValue,
  isEmptyStr,
  printMenuOptions,
  inReachOptional,
  handleResponseError,
  formatAvatarPath,
  formatAreas,
}

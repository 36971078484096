import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Input, Heading, Card } from 'rebass'
import Button from './Elements/Button'
import GlobeIcon from '../assets/svg-icons/user.svg'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'
import { navigate } from 'gatsby-link'
const apiUrl = process.env.MYSTORE_MS_API_URL
import { useLocation } from '@reach/router'

import { HttpService } from '../../mystore-frontend-infrastructure/services/index'
import { has } from 'lodash'
import FullScreenLoader from './FullScreenLoader'
import { CookieStorage } from '../../mystore-frontend-infrastructure/services/CookieService'
import GoogleLogo from '../assets/google-logo.svg'
import { extractHashParams } from '../utils'

const FormWrapper = styled.div`
  margin-top: 3rem;

  @media (max-width: 48em) {
    padding: 0 1.5rem;
  }
`
const LoginButton = styled(Button)`
  width: 202px;
`

const AdminIcon = styled.img`
  width: 30px;
`
const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 0.25rem;
  background: white;
`

const Login = props => {
  const tokenKey = 'id_token'
  const { hash } = useLocation()
  const [userState, setUserState] = useState({
    email: '',
    password: '',
    user: null,
    customState: null,
  })

  const handleChange = e => {
    e.persist()
    setUserState(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const setToken = token => {
    CookieStorage.set('token', token)
  }

  const handleLogin = () => {
    const { email, password } = userState
    if (email.length > 0 && password.length > 0) {
      // props.showButtonLoading()
      props.showFullScreenLoader()
      HttpService()
        .httpRequest(
          `users-ms/login?`,
          {
            method: 'POST',
            body: JSON.stringify({
              user_id: email,
              password: password,
            }),
          },
          apiUrl
        )
        .then(response => {
          if (has(response, 'token')) {
            setToken(response.token)
            props.login()
            props.get_config(function() {
              console.log('finished get_config')
            })
            props.setUser(email)
            navigate('/')
          }
        })
        .catch(e => {})
        .finally(() => {
          props.hideFullScreenLoader()
        })
    } else {
      props.handleAlert({
        text: 'Add user id and password first!',
        type: 'warning',
      })
    }
  }

  const handleAutoLogin = () => {
    const urlHashObj = extractHashParams(hash)
    if (has(urlHashObj, tokenKey)) {
      setToken(urlHashObj[tokenKey])
      props.login()
      props.get_config(function() {
        console.log('finished get_config')
      })
      props.setUser(userState.email)
      navigate('/')
    }
  }

  useEffect(handleAutoLogin, [hash])

  return (
    <FormWrapper className="login-form form-wrapper">
      <LoginCard py={4} px={4}>
        <Heading textAlign="center" fontSize={3} fontWeight={200} mb={5}>
          Admin login page <AdminIcon src={GlobeIcon} />
        </Heading>
        <Input
          onChange={handleChange}
          placeholder="User Id"
          name="email"
          type="text"
          mb={3}
          px={3}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              handleLogin()
            }
          }}
        />
        <Input
          onChange={handleChange}
          placeholder="Password"
          name="password"
          type="password"
          mb={4}
          px={3}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              handleLogin()
            }
          }}
        />
        <LoginButton disabled>Login</LoginButton>
        <h6>click on google buttong to login</h6>
        <a
          href={`${process.env.AUTH_API_URL}/login?client_id=${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${process.env.REDIRECT_URL}`}
        >
          <Button bg="#fff">
            <img src={GoogleLogo} alt="google authentication" />
          </Button>
        </a>
      </LoginCard>
      <FullScreenLoader loading={props.loading} />
    </FormWrapper>
  )
}

Login.propTypes = {}

const mapStateToProps = ({ loading, auth }) => ({
  loading,
  auth,
})

export default connect(mapStateToProps, actions)(Login)

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PageNotFound from '../../assets/404Page.jpg'
import { navigate } from 'gatsby-link'
import { getNow } from '../../utils/dateHandler'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'

const NavItem = styled.span`
  color: #718eb1;
  margin: 0;
  padding: 1.5rem 1.25rem 1.5rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid rgba(47, 46, 68, 0.03);
  cursor: pointer;
`
const Img = styled.img`
  max-width: 1000px;
`

class ErrorPage extends React.Component {
  componentDidMount() {
    const ErrorMassageFormat = `*${getNow()}* *Admin Error*
        User-${this.props.adminUser} Retail-${this.props.selected_rid} Store-${
      this.props.selected_sid
    }
        \t*Error Massage:*
        \t\t${this.props.errorMassage}\t
        *Error Stuck:*\t\t${this.props.errorSuck}`

    fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'POST',
        body: JSON.stringify({ text: ErrorMassageFormat }),
        mode: 'no-cors',
        ContentType: 'application/json',
      }
    ).then(res => {})
  }

  render() {
    return (
      <div>
        {' '}
        <p style={{ textAlign: 'center' }}>
          <NavItem onClick={() => navigate('/')}>Go Back to safety</NavItem>
          <Img alt="PageNotFound" src={PageNotFound} />
        </p>
      </div>
    )
  }
}

const mapStateToProps = ({ selected_rid, selected_sid, adminUser }) => ({
  selected_rid,
  selected_sid,
  adminUser,
})

ErrorPage.propTypes = {
  errorSuck: PropTypes.string,
  errorMassage: PropTypes.string,
}

export default connect(
  mapStateToProps,
  actions
)(ErrorPage)

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import ErrorIcon from '../../../../src/assets/svg-icons/cancel.svg'
import SuccessIcon from '../../../../src/assets/svg-icons/checked.svg'
import InfoIcon from '../../../../src/assets/svg-icons/info_2.svg'
import WarningIcon from '../../../../src/assets/svg-icons/round-error-symbol.svg'

const alertColors = {
  warning: '#ffe79e',
  error: '#cc0000',
  info: '#0099ff',
  success: '#4caf50',
}

const alertIcons = {
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
}

const Icon = styled.img`
  width: 35px;
  height: 35px;
  padding: 0 0 0px 0;
  margin: 0 20px 0 0;
  border-radius: 17px;
  border: solid black 2px;
  background-color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const AlertWrap = styled.div`
  position: relative;
  background: ${props => alertColors[props.className]};
  min-width: 300px;
  border-radius: 10px;
  color: black;
  padding: 1rem;
  margin-bottom: 5px;
  opacity: ${props => (props.show ? '0.8' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transform: translateX(${props => (props.show ? '-20px' : '120px')});
  transition: all 300ms ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`

const Text = styled.div`
  margin: 0px 0 0 0px;
  max-width: 300px;
`

const TypeHeader = styled.h4`
  margin-bottom: 7px;
`

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

async function removeAlertById(id, removeCall) {
  await timeout(5000)
  removeCall(id)
}

const Alert = props => {
  const [show, toggle] = useState(false)
  useEffect(() => {
    toggle(true)
    removeAlertById(props.id, props.removeAlert)
  })

  return (
    <AlertWrap className={props.type} show={show}>
      <Icon src={alertIcons[props.type]} />
      <Body>
        <TypeHeader>
          {props.type.charAt(0).toUpperCase() + props.type.slice(1)}:
        </TypeHeader>
        <Text>{props.children}</Text>
      </Body>
    </AlertWrap>
  )
}

export default connect(
  null,
  actions
)(Alert)

import React, { useEffect, useState } from 'react'
import { Toolbar } from 'rebass'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import MenuIcon from '../../assets/svg-icons/menu-white.svg'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '../Elements/Buttons/Button'
import { isSolid } from '../../utils/taskHandler'
import { useLocation } from '@reach/router'

const StyledToolBar = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  outline: none;
`

const SiteNavi = props => {
  const [retailOptions, setRetailOptions] = useState([])
  const location = useLocation()
  useEffect(() => {
    if (!isSolid(props.selected_rid) || !isSolid(props.retails)) {
      props.showFullScreenLoader()
      props.get_config()
      props.hideFullScreenLoader()
    }
    if (
      props.selected_rid !== null &&
      (location.pathname === '/tags/' ||
        location.pathname === '/groups/' ||
        location.pathname === '/retailConfig/' ||
        location.pathname === '/create-pop/')
    ) {
      props.getTags(props.selected_rid)
    }
  }, [props.selected_rid, location.pathname])

  const retails_select_options = () => {
    try {
      let opt = []
      props.retails.forEach((key, index) => {
        opt.push({
          id: index,
          key: index,
          title: key,
          value: key,
        })
      })

      return opt
    } catch (e) {
      return [
        {
          id: -1,
          key: -1,
          title: 'no retails',
          value: -1,
        },
      ]
    }
  }

  useEffect(() => {
    setRetailOptions(retails_select_options())
  }, [props.retails])

  const onRidSelect = (event, val) => {
    let rid = val.value
    props.setSelectedRid(rid)
    props.setUsers([])
    props.setTasksData({})
    props.setAppTasks([])
    props.setReportTasks([])
    props.setPosts([])
    props.setPostRef([])
    props.setSelectedGroup({})
  }

  const getValue = () => {
    let selected = null
    retailOptions.forEach(option => {
      if (option.value === props.selected_rid) {
        selected = option
      }
    })
    return selected
  }

  return (
    <StyledToolBar className="toolbar" py={2} bg={'#424858'}>
      <div style={{ display: 'flex' }}>
        <MenuButton onClick={props.toggleDrawer}>
          <img width={20} src={MenuIcon} />
        </MenuButton>
        <div
          style={{
            background: '#eeeeee',
            padding: '2px',
            borderRadius: '5px',
            color: 'white',
          }}
        >
          <Autocomplete
            disableClearable={true}
            id="combo-box-demo"
            options={retailOptions}
            style={{ width: 300 }}
            getOptionLabel={option => option.title}
            onChange={onRidSelect}
            value={getValue()}
            renderInput={params => <TextField {...params} fullWidth />}
          />
        </div>
      </div>

      <Button onClick={props.logout} color={'default'} label={'Logout'} />
    </StyledToolBar>
  )
}
const mapStateToProps = ({ auth, retails, selected_rid, loading }) => ({
  auth,
  retails,
  selected_rid,
  loading,
})

export default connect(mapStateToProps, actions)(SiteNavi)

export class LocalStorage {
  static setData = (key, data) => {
    return localStorage.setItem(key, JSON.stringify(data))
  }

  static getData = key => {
    return JSON.parse(localStorage.getItem(key))
  }

  static removeData = key => {
    localStorage.removeItem(key)
  }
}
import React from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import loaderIcon from '../assets/svg-loaders/bars-black.svg'

import posed from 'react-pose'

const LoaderProps = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}

const LoaderWrapper = styled(posed.div(LoaderProps))`
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 300ms ease-in-out;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`
const Loader = styled.img`
  max-width: 70px;
`
const FullScreenLoader = props => {
  return (
    <LoaderWrapper
      pose={props.loading ? 'visible' : 'hidden'}
      show={props.loading ? 1 : 0}
    >
      <Loader src={loaderIcon} />
    </LoaderWrapper>
  )
}

FullScreenLoader.propTypes = {}

const mapStateToProps = ({ loading }) => ({
  loading,
})

export default connect(mapStateToProps)(FullScreenLoader)

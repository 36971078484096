import React from 'react'
import styled from 'styled-components'

const AlertContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 9999;
`

export default AlertContainer

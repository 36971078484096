import React from 'react'
import PropTypes from 'prop-types'
import { Button as Btn } from 'rebass'
import styled from 'styled-components'

const CustomButton = styled(Btn)`
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.125));
  /*box-shadow: 0 5px 10px rgba(43, 42, 68, 0.29);*/
  cursor: pointer;
  background-color: ${props => props.bg};
  /* border: 1px solid #49486b; */
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  padding: 8px 20px;
  transition: all 300ms ease-in-out;
  line-height: 1.4em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-weight: 400;
  height: fit-content;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    box-shadow: none;
  }
`

const Button = props => {
  return (
    <CustomButton bg={props.bg} mb={3} onClick={props.onClick} {...props}>
      {props.children}
    </CustomButton>
  )
}

Button.defaultProps = {
  bg: '#49486b',
}

Button.propTypes = {
  bg: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button

import { navigate } from 'gatsby-link'
import { HttpService } from '../../mystore-frontend-infrastructure/services/index'
import { generateID, isSolid } from '../utils/taskHandler'
import { CookieStorage } from '../../mystore-frontend-infrastructure/services/CookieService'
const apiUrl = process.env.MYSTORE_MS_API_URL

const actions = store => ({
  setUser: (state, uid) => {
    store.setState({ adminUser: uid })
  },

  login: state => {
    store.setState({ auth: true })
  },

  checkToken: state => {
    if (CookieStorage.get('token') !== '"{}"' && CookieStorage.get('token')) {
      store.setState({
        auth: true,
        currentUser: CookieStorage.get('token'),
      })
    }
  },
  hideFullScreenLoader: state => {
    store.setState({ loading: false })
  },
  showFullScreenLoader: state => {
    store.setState({ loading: true })
  },

  addChangedTask: (state, task_id) => {
    let temp = [...state.changedTasks]
    temp.push(task_id)
    store.setState({
      changedTasks: [...Array.from(new Set(temp))],
    })
  },
  setDynamicTaskTypesView: (state, taskTypesView) => {
    store.setState({ taskTypesView: taskTypesView })
  },

  setSubTasksToDelete: (state, taskId, subTaskId) => {
    let temp = { ...state.subTasksToDelete }
    if (temp[taskId] === undefined) {
      temp[taskId] = [subTaskId]
    } else {
      temp[taskId].push(subTaskId)
    }
    store.setState({ subTasksToDelete: temp })
  },

  addNonExpendableTask: (state, taskId) => {
    let temp = [...state.nonExpandable]
    temp.push(taskId)
    store.setState({ nonExpandable: temp })
  },

  handleAlert: (state, alert) => {
    alert['id'] = generateID()
    const alerts = [...state.alerts, alert]
    store.setState({
      alerts: alerts,
    })
  },

  removeAlert: (state, id) => {
    let alerts = [...state.alerts]
    let newAlerts = alerts.filter(alert => alert.id !== id)
    store.setState({
      alerts: newAlerts,
    })
  },

  setMasterKeys: (state, masterKey) => {
    let temp = [...state.masterKeys]
    temp.push(masterKey)
    store.setState({ masterKeys: temp })
  },

  removeChangedTask: (state, task_id) => {
    let temp = [...state.changedTasks]
    let i = temp.indexOf(task_id)

    if (i >= 0) {
      temp.splice(i, 1)
      let t = [...Array.from(new Set(temp))]
      store.setState({
        changedTasks: t,
      })
    }
  },

  getTags: async props => {
    try {
      const tags = await HttpService().httpRequest(
        `users-ms/tag?rid=${props.selected_rid} `,
        {
          method: 'GET',
        }
      )
      store.setState({
        retailTags: tags,
      })
    } catch (error) {
      console.log(error)
    }
  },

  get_config: async () => {
    try {
      let response = await HttpService().httpRequest(
        `users-ms/config?`,
        {
          method: 'GET',
        },
        apiUrl
      )
      if (response.status === 200) {
        store.setState({
          statusMenus: response.status_menus,
          userRoles: response.user_roles_map,
          groupLevelMap: response.group_level_map,
          actionPagesMap: response.action_page_map,
          reportFetchRangesMap: response.report_fetch_range_map,
          userStatusMap: response.user_status,
          selected_rid: response.retails[0],
          retails: response.retails,
          permissions: response.permissions_types_map,
          taskTags: [
            { key: 'potential', title: 'Foucs Items' },
            { key: 'operational', title: 'Floor Set' },
            { key: 'best_seller', title: 'Best Seller' },
          ],
          avatar_path: response.avatars_path,
          cdn: response.cdn,
          etl_actions: response.etl_actions,
        })
      } else {
        if (response.status === 500) {
          alert('Configuration server error ask for R&D')
        }
        store.setState({
          loading: false,
          selectedTask: { data_oid: null, task_id: null, data_order: null },
          selected_item: null,
          selected_todate: null,
          selected_fromdate: null,
          tasks: null,
          tasksData: null,
          users: [],
          thisWeek: false,
          enableAddTasks: false,
          showTasksModal: false,
        })
        CookieStorage.delete('token')
        navigate('/')
      }
    } catch (error) {
      if (error?.status === 401) {
        store.reset()
      }
    }
  },

  logout: state => {
    store.setState({
      loading: true,
    })
    CookieStorage.clearAll()
    navigate('/')
    store.setState({
      auth: false,
      loading: false,
      selectedTask: { data_oid: null, task_id: null, data_order: null },
      selected_item: null,
      selected_todate: null,
      selected_fromdate: null,
      tasks: null,
      tasksData: null,
      users: [],
      thisWeek: false,
      enableAddTasks: false,
      showTasksModal: false,
    })
  },
  setOpenStoreSelector: (state, bool) => {
    store.setState({ openStoreSelector: bool })
  },
  toggleDrawer: state => ({
    drawer: !state.drawer,
  }),
  setSelectedRid: (state, rid) => {
    store.setState({ selected_rid: rid })
  },

  setSelectedSid: (state, sid) => {
    store.setState({ selected_sid: sid })
  },

  setExpendedCategory: (state, cat) => {
    store.setState({ expandedCategory: cat })
  },
  setSelectedProduct: (state, product) => {
    store.setState({ selectedProduct: product })
  },
  setSelectedPost: (state, post) => {
    store.setState({ selectedPost: post })
  },
  setSelectedSubTask: (state, subTask) => {
    store.setState({ selectedSubTask: subTask })
  },
  setTasksAndTasksData: (state, tasks, tasksData) => {
    store.setState({ tasks: tasks, tasksData: tasksData })
  },
  setSubTasks: (state, tasksData) => {
    store.setState({ subTasks: tasksData })
  },
  setTasksData: (state, tasksData) => {
    store.setState({ tasksData: tasksData })
  },
  setAppTasks: (state, tasks) => {
    store.setState({ tasks: tasks })
  },
  setAppTaskTypes: (state, taskTypes) => {
    store.setState({ taskTypes: taskTypes })
  },
  setReportTasks: (state, tasks) => {
    store.setState({ reportTasks: tasks })
  },
  setEditProduct: (state, bool) => {
    store.setState({ editProduct: bool })
  },
  setShowItemModal: (state, bool) => {
    store.setState({ showItemModal: bool })
  },
  setSelectedRow: (state, row) => {
    store.setState({ selectedRow: row })
  },
  setSelectedTask: (state, task) => {
    store.setState({ selectedTask: task })
  },
  setShowCategoryModal: (state, bool) => {
    store.setState({ showCategoryModal: bool })
  },
  setTaskTypeModal: (state, bool) => {
    store.setState({ showTaskTypeModal: bool })
  },
  setTaskTypeModalEdit: (state, bool) => {
    store.setState({ editTaskTypeModal: bool })
  },
  setSelectedTypeTaskModal: (state, task) => {
    store.setState({ selectedTypeTask: task })
  },

  setSelectedUser: (state, user) => {
    store.setState({ selectedUser: user })
  },
  setTaskSelectedUser: (state, user) => {
    store.setState({ taskSelectedUser: user })
  },
  setShowUserModal: (state, bool) => {
    store.setState({ showUserModal: bool })
  },
  setShowPostModal: (state, bool) => {
    store.setState({ showPostModal: bool })
  },
  setUsers: (state, users) => {
    store.setState({ users: users })
  },
  setEditUser: (state, bool) => {
    store.setState({ editUser: bool })
  },
  setShowDeleteUserWarningModal: (state, bool) => {
    store.setState({ showDeleteUserWarningModal: bool })
  },
  setShowDeleteTaskWarningModal: (state, bool) => {
    store.setState({ showDeleteTaskWarningModal: bool })
  },
  setShowDeleteCategoryWarningModal: (state, bool) => {
    store.setState({ showDeleteCategoryWarningModal: bool })
  },
  setShowSubmitTaskWarningModal: (state, bool) => {
    store.setState({ showSubmitTaskWarningModal: bool })
  },
  setShowCopyTaskModal: (state, bool) => {
    store.setState({ showCopyTaskModal: bool })
  },
  setShowCloneTaskModal: (state, bool) => {
    store.setState({ showCloneTaskModal: bool })
  },
  setShowAssigneeTaskModal: (state, bool) => {
    store.setState({ showAssigneeTaskModal: bool })
  },
  setCloneMode: (state, cloneMode) => {
    store.setState({ cloneMode })
  },
  setShowProductModal: (state, bool) => {
    store.setState({ showProductModal: bool })
  },
  setShowValidationPassword: (state, bool) => {
    store.setState({ showValidationPassword: bool })
  },
  setEnableAddTasks: (state, bool) => {
    store.setState({ enableAddTasks: bool })
  },
  setShowTasksModal: (state, bool) => {
    store.setState({ showTasksModal: bool })
  },
  setCreatingTaskOfType: (state, taskType) => {
    store.setState({ creatingTaskOfType: taskType })
  },
  setCreatingSubTaskOfType: (state, type) => {
    store.setState({ creatingSubTaskOfType: type })
  },
  setTabNum: (state, num) => {
    store.setState({ tabNum: num })
  },
  setEditTask: (state, bool) => {
    store.setState({ editTask: bool })
  },
  setEditCategory: (state, bool) => {
    store.setState({ editCategory: bool })
  },
  setEditItem: (state, bool) => {
    store.setState({ editItem: bool })
  },
  setEditPost: (state, bool) => {
    store.setState({ editPost: bool })
  },
  setEditSubTask: (state, bool) => {
    store.setState({ editSubTask: bool })
  },
  setSelectedCategory: (state, cat) => {
    store.setState({ selectedCategory: cat })
  },
  setSelectedTag: (state, tag) => {
    store.setState({
      selectedTag: tag,
    })
  },
  setShowImageCarouselModal: (state, bool) => {
    store.setState({ showImageCarouselModal: bool })
  },
  setCarouselModalImages: (state, images) => {
    store.setState({ carouselModalImages: images })
  },
  closeCategoryModal: state => {
    let _state = { showCategoryModal: false }
    if (state.editCategory) {
      _state['editCategory'] = false
    }
    store.setState(_state)
  },
  closeTaskTypeModal: state => {
    let _state = { showTaskTypeModal: false }
    if (state.showTaskTypeModal) {
      _state['editTaskTypeModal'] = false
    }
    store.setState(_state)
  },
  closeTaskTypeModal: state => {
    let _state = { showTaskTypeModal: false }
    if (state.editTaskTypeModal) {
      _state['editTaskTypeModal'] = false
    }
    store.setState(_state)
  },
  setFetchedUsers: (state, uid, user) => {
    let fetchedUsers = state.fetchedUsers
    if (isSolid(fetchedUsers[uid])) {
      fetchedUsers[uid] = { ...fetchedUsers[uid], ...user }
    } else {
      fetchedUsers[uid] = user
    }
    store.setState({ fetchedUsers: fetchedUsers })
  },
  handleTaskFetcherStoreSelect: (state, storeId) => {
    store.setState({
      tasks: [],
      reportTasks: [],
      subTasks: {},
      tasksData: {},
    })
  },

  showImageCarousel: (state, images) => {
    store.setState({
      carouselModalImages: images,
      editSubTask: false,
      showCategoryModal: false,
      showImageCarouselModal: true,
    })
  },

  finishProductSubmit: (state, subTasks, tasksData) => {
    store.setState({
      tasksData: tasksData,
      subTasks: subTasks,
      showProductModal: false,
      editProduct: false,
    })
  },

  setEnableFileUpload: (state, bool) => {
    store.setState({ enableFileUpload: bool })
  },

  setPosts: (state, posts) => {
    store.setState({ posts: posts })
  },
  setPostRef: (state, ref) => {
    store.setState({ postRef: ref })
  },
  setNotificationSelectedUsers: (state, users) => {
    store.setState({ notificationSelectedUsers: users })
  },
  setRetailConfig: (state, retailId, config) => {
    let retailsConfig = state.retailsConfig
    retailsConfig[retailId] = config
    store.setState({ retailsConfig: retailsConfig })
    store.setState({
      subRidIds: retailsConfig[retailId].retail_sub_retail_ids,
    })
  },

  setRetailDataConfigs: (state, retailId, config) => {
    store.setState({ retailsDataConfigs: { [retailId]: config } })
  },
  setReports: (state, reports) => {
    store.setState({ reports: reports })
  },
  setGroups: (state, groups) => {
    // let retailsConfig = state.groups
    // retailsConfig[retailId] = config
    store.setState({ groups: groups })
  },
  setShowGroupModal: (state, bool) => {
    store.setState({ showGroupModal: bool })
  },
  setShowDeleteGroupWarningModal: (state, bool) => {
    store.setState({ showDeleteGroupWarningModal: bool })
  },
  setEditGroup: (state, bool) => {
    store.setState({ editGroup: bool })
  },
  setSelectedGroup: (state, group) => {
    store.setState({ selectedGroup: group })
  },
  addTag: (state, body) => {
    store.setState({
      retailTags: [...state.retailTags, { name: body.tagName, sk: body.sk }],
    })
  },
  updateRetailTags: (state, tags) => {
    store.setState({ retailTags: tags })
  },
  setIsLoading: (state, bool) => {
    store.setState({ loading: bool })
  },
  setStoresIds: (state, ids) => {
    store.setState({ storesIds: ids })
  },
  setStoreDepartments: (state, departments) => {
    store.setState({ storeDepartments: departments })
  },
  setDataConfigs: (state, configs) => {
    store.setState({ dataConfigs: configs })
  },
  setUsersDataIds: (state, ids) => {
    store.setState({ usersDataIds: ids })
  },
})

export default actions

import React from 'react'
import { Button as Btn } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { green, purple } from '@material-ui/core/colors'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const PurpleButton = withStyles(theme => ({
  root: {
    color: purple[800],
    '&:hover': {
      backgroundColor: purple[100],
    },
  },
}))(Btn)

const GreenButton = withStyles(theme => ({
  root: {
    color: green[800],
    '&:hover': {
      backgroundColor: green[100],
    },
  },
}))(Btn)

const Button = props => {
  const classes = useStyles()

  return props.color === 'purple' ? (
    <PurpleButton
      variant="contained"
      className={classes.button}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
    >
      {props.label}
    </PurpleButton>
  ) : props.color === 'green' ? (
    <GreenButton
      variant="contained"
      className={classes.button}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
    >
      {props.label}
    </GreenButton>
  ) : (
    <Btn
      variant="contained"
      color={props.color}
      className={classes.button}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
    >
      {props.label}
    </Btn>
  )
}

Button.defaultProps = {
  color: 'primary',
  type: 'button',
}

Button.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default Button
